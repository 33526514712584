import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

export default ({ data }) => (
    <Layout>
      <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <Link
            to={node.fields.slug}
          >
            <h3>{node.frontmatter.title}{" "}
              <span style={{ color: `#bbb` }}>- {node.frontmatter.date}</span>
            </h3>
            <p style={{ color: `#000`}}>{node.excerpt}</p>
          </Link>
        </div>
      ))}
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`